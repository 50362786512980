const AmazonLink = ({link}) => {
  return (
    <iframe
      title="Amazon Link"
      style={{width: "120px", height: "240px"}}
      marginWidth="0"
      marginHeight="0"
      scrolling="no"
      frameBorder="0"
      src={link}
    />
  )
}

export default AmazonLink;
