const explanations = {
  voltage: {
    title: "Voltage",
    content: "Enter the voltage at the source of the circuit. Usually, 12v, 24v or 48v are the most used.",
    video: "TBt-kxYfync",
  },
  amperes: {
    title: "Amperes",
    content: "Enter the maximum current in amps that will flow through the circuit.",
    video: "8gvJzrjwjds",
  },
  phases: {
    title: "Phases",
    content: "Select the number of phases in the circuit. This is typically single-phase or three-phase. For single-phase circuits, three wires are required. For three-phase circuits, four wires are required. One of these wires is a ground wire which can be sized down."
  },
  insulation: {
    title: "Insulation",
    content: "Select the thermal rating of the insulation on the wire."
  },
  conductor: {
    title: "Conductor",
    content: "Choose the material used as a conductor in the wire. Common conductors are copper and aluminum but we strongly recommand to only use copper."
  },
  installation: {
    title: "Installation",
    content: "Choose the installation method for the circuit. This is typically in a raceway (cable-tray or conduit), in a cable, burried in the Earth, or in open air."
  },
  voltagedrop: {
    title: "Voltage Drop",
    content: "Choose the maximum percentage of the source voltage drop. It is recommended not to exceed a voltage drop of 5%."
  },
  distance: {
    title: "Distance",
    content: "Enter the one-way length of the wires in the circuit in feet. " +
      "Do not forget that the electrical resistance builds up as the wires gets longer."
  },
};



const wireGauge = {
  "14 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B016TQKT9K&asins=B016TQKT9K&linkId=8f8a4e98747d50b3faa2e7f90b6af64d&show_border=true&link_opens_in_new_window=true"},
  "12 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B06XR9JTP7&asins=B06XR9JTP7&linkId=33e07d5754f73d619dc5ad9fa700f38f&show_border=true&link_opens_in_new_window=true"},
  "10 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B003J69XK0&asins=B003J69XK0&linkId=3df9edfd9e883103d208a2a2b48da765&show_border=true&link_opens_in_new_window=true"},
  "8 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01M8QVZTN&asins=B01M8QVZTN&linkId=9cc237d050860d6e543fdbcc8c520510&show_border=true&link_opens_in_new_window=true"},
  "6 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01MD1ZGKO&asins=B01MD1ZGKO&linkId=d8ed97e6c0b49c3bed207eeb86487e6d&show_border=true&link_opens_in_new_window=true"},
  "4 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01MD21BCC&asins=B01MD21BCC&linkId=72311448aa68becfc76910290645d829&show_border=true&link_opens_in_new_window=true"},
  "3 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B08FTL8K2R&asins=B08FTL8K2R&linkId=0ff969056264794146dbe600374648a3&show_border=true&link_opens_in_new_window=true"},
  "2 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01M35SPAU&asins=B01M35SPAU&linkId=2866818205fcfdf785559ab2d41a22b2&show_border=true&link_opens_in_new_window=true"},
  "1 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01M35S1J2&asins=B01M35S1J2&linkId=717df2a5411c2873606e913f6697c37b&show_border=true&link_opens_in_new_window=true"},
  "1/0 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01MD1XRSL&asins=B01MD1XRSL&linkId=1626f9c7718cfaa3269352053a99c491&show_border=true&link_opens_in_new_window=true"},
  "2/0 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01M35S1J2&asins=B01M35S1J2&linkId=913f64fe788f0813646b7ccbcb10553c&show_border=true&link_opens_in_new_window=true"},
  "3/0 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01MD1YL1I&asins=B01MD1YL1I&linkId=08bc429625637649a73cb76958365d57&show_border=true&link_opens_in_new_window=true"},
  "4/0 AWG": {amz: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B01MD1YL1I&asins=B01MD1YL1I&linkId=08bc429625637649a73cb76958365d57&show_border=true&link_opens_in_new_window=true"},
  "250 kcmil": {},
  "300 kcmil": {},
  "350 kcmil": {},
  "400 kcmil": {},
  "500 kcmil": {},
  "600 kcmil": {},
  "700 kcmil": {},
  "750 kcmil": {},
  "800 kcmil": {},
  "900 kcmil": {},
  "1000 kcmil": {},
  "1250 kcmil": {},
  "1500 kcmil": {},
  "1750 kcmil": {},
  "2000 kcmil": {}
}

export {
  explanations,
  wireGauge
};
