const amps60 = [15, 20, 30, 40, 55, 70, 85, 95, 110, 125, 145, 165, 195, 215, 240, 260, 280, 320, 355, 385, 400, 410, 435, 455, 495, 520, 545, 560];
const amps75 = [20, 25, 35, 50, 65, 85, 100, 115, 130, 150, 175, 200, 230, 255, 285, 310, 335, 380, 420, 460, 475, 490, 520, 545, 590, 625, 650, 665];
const amps90 = [25, 30, 40, 55, 75, 95, 115, 130, 145, 170, 195, 225, 260, 290, 320, 350, 380, 430, 475, 520, 535, 555, 585, 615, 665, 705, 735, 750];
const amps60al = [0, 20, 25, 30, 40, 55, 65, 75, 85, 100, 115, 130, 150, 170, 190, 210, 225, 260, 285, 310, 320, 330, 355, 375, 405, 435, 455, 470];
const amps75al = [0, 20, 30, 40, 50, 65, 75, 90, 100, 120, 135, 155, 180, 205, 230, 250, 270, 310, 340, 375, 385, 395, 425, 445, 485, 520, 545, 560];
const amps90al = [0, 25, 35, 45, 60, 75, 85, 100, 115, 135, 150, 175, 205, 230, 255, 280, 305, 350, 385, 420, 435, 450, 480, 500, 545, 585, 615, 630];
const fa60 = [25, 30, 40, 60, 80, 105, 120, 140, 165, 195, 225, 260, 300, 340, 375, 420, 455, 515, 575, 630, 655, 680, 730, 780, 890, 980, 1070, 1155];
const fa75 = [30, 35, 50, 70, 95, 125, 145, 170, 195, 230, 265, 310, 360, 405, 445, 505, 545, 620, 690, 755, 785, 815, 870, 935, 1065, 1175, 1280, 1385];
const fa90 = [35, 40, 55, 80, 105, 140, 165, 190, 220, 260, 300, 350, 405, 455, 505, 570, 615, 700, 780, 855, 885, 920, 985, 1055, 1200, 1325, 1445, 1560];
const fa60al = [0, 25, 35, 45, 60, 80, 95, 110, 130, 150, 175, 200, 235, 265, 290, 330, 355, 405, 455, 500, 515, 535, 580, 625, 710, 795, 875, 960];
const fa75al = [0, 30, 40, 55, 75, 100, 115, 135, 155, 180, 210, 240, 280, 315, 350, 395, 425, 485, 540, 595, 620, 645, 700, 750, 855, 950, 1050, 1150];
const fa90al = [0, 35, 40, 60, 80, 110, 130, 150, 175, 205, 235, 275, 315, 355, 395, 445, 480, 545, 615, 675, 700, 725, 785, 845, 960, 1075, 1185, 1335];
const kcmil = [4110, 6530, 10380, 16510, 26240, 41740, 52620, 66360, 83690, 105600, 133100, 167800, 211600, 250000, 300000, 350000, 400000, 500000, 600000, 700000, 750000, 800000, 900000, 1000000, 1250000, 1500000, 1750000, 2000000];
const wires = ["14 AWG", "12 AWG", "10 AWG", "8 AWG", "6 AWG", "4 AWG", "3 AWG", "2 AWG", "1 AWG", "1/0 AWG", "2/0 AWG", "3/0 AWG", "4/0 AWG",
  "250 kcmil", "300 kcmil", "350 kcmil", "400 kcmil", "500 kcmil", "600 kcmil", "700 kcmil", "750 kcmil", "800 kcmil", "900 kcmil",
  "1000 kcmil", "1250 kcmil", "1500 kcmil", "1750 kcmil", "2000 kcmil"];

const wiresizeFunction = ({
  maxamps,
  voltsource,
  droppercent,
  conrun,
  wscond,
  phase,
  insulation,
  raceway
}) => {
  const amps = maxamps;
  const volts = voltsource;
  const drop = droppercent;
  const length = conrun;
  const K = wscond;
  const conductorjs = wscond === "12.9" ? 0 : 1;
  const p = phase;
  const ins = insulation;
  const racewayjs = raceway;

  let ans60 = 0;
  let ans75 = 0;
  let ans90 = 0;
  let sizevd = 0;
  let sizevs = 0;

  if (amps > 0 && volts > 0 && length > 0) {
    var Vd = volts * drop;
    var Cm = p * K * length * amps / Vd;

    for (let i = 0; i < kcmil.length; i++) {
      if (kcmil[i] >= Cm) {
        sizevd = i;
        break;
      } else if (Cm > 2000000) {
        sizevd = 30;
      }
    }

    if (conductorjs === 0 && racewayjs < 3) {
      if (ins === 0) {
        ans60 = amps;
        for (let i = 0; i < amps60.length; i++) {
          if (amps60[i] > ans60) {
            sizevs = i;
            break;
          } else if (amps > 560) {
            sizevs = 30;
          }
        }
      }
      else if (ins === 1) {
        ans75 = amps;
        for (let i = 0; i < amps75.length; i++) {
          if (amps75[i] > ans75) {
            sizevs = i;
            break;
          } else if (amps > 665) {
            sizevs = 30;
          }
        }
      }
      else if (ins === 2) {
        ans90 = amps;
        for (let i = 0; i < amps90.length; i++) {
          if (amps90[i] > ans90) {
            sizevs = i;
            break;
          } else if (amps > 750) {
            sizevs = 30;
          }
        }
      }
    }
    else if (conductorjs === 1 && racewayjs < 3) {
      if (ins === 0) {
        ans60 = amps;
        for (let i = 0; i < amps60al.length; i++) {
          if (amps60al[i] > ans60) {
            sizevs = i;
            break;
          } else if (amps > 470) {
            sizevs = 30;
          }
        }
      }
      else if (ins === 1) {
        ans75 = amps;
        for (let i = 0; i < amps75al.length; i++) {
          if (amps75al[i] > ans75) {
            sizevs = i;
            break;
          } else if (amps > 560) {
            sizevs = 30;
          }
        }
      }
      else if (ins === 2) {
        ans90 = amps;
        for (let i = 0; i < amps90al.length; i++) {
          if (amps90al[i] > ans90) {
            sizevs = i;
            break;
          } else if (amps > 630) {
            sizevs = 30;
          }
        }
      }
    }
    else if (conductorjs === 0 && racewayjs === 3) {
      if (ins === 0) {
        ans60 = amps;
        for (let i = 0; i < fa60.length; i++) {
          if (fa60[i] > ans60) {
            sizevs = i;
            break;
          } else if (amps > 1155) {
            sizevs = 30;
          }
        }
      }
      else if (ins === 1) {
        ans75 = amps;
        for (let i = 0; i < fa75.length; i++) {
          if (fa75[i] > ans75) {
            sizevs = i;
            break;
          } else if (amps > 1385) {
            sizevs = 30;
          }
        }
      }
      else if (ins === 2) {
        ans90 = amps;
        for (let i = 0; i < fa90.length; i++) {
          if (fa90[i] > ans90) {
            sizevs = i;
            break;
          } else if (amps > 1560) {
            sizevs = 30;
          }
        }
      }
    }
    else if (conductorjs === 1 && racewayjs === 3) {
      if (ins === 0) {
        ans60 = amps;
        for (let i = 0; i < fa60al.length; i++) {
          if (fa60al[i] > ans60) {
            sizevs = i;
            break;
          } else if (amps > 1155) {
            sizevs = 30;
          }
        }
      }
      else if (ins === 1) {
        ans75 = amps;
        for (let i = 0; i < fa75al.length; i++) {
          if (fa75al[i] > ans75) {
            sizevs = i;
            break;
          } else if (amps > 1385) {
            sizevs = 30;
          }
        }
      }
      else if (ins === 2) {
        ans90 = amps;
        for (let i = 0; i < fa90al.length; i++) {
          if (fa90al[i] > ans90) {
            sizevs = i;
            break;
          } else if (amps > 1560) {
            sizevs = 30;
          }
        }
      }
    }

    const wsansEl = document.getElementById("wsans") || {innerHTML: ""};
    const wsloadansEl = document.getElementById("wsloadans") || {innerHTML: ""};

    if (sizevd === 30 || sizevs === 30) {
      wsansEl.innerHTML = "> 2000 kcmil";
      wsloadansEl.innerHTML = volts - (volts * drop) + " V";
    }
    else if (sizevd >= sizevs) {
      wsansEl.innerHTML = wires[sizevd];
      wsloadansEl.innerHTML = volts - (volts * drop) + " V";
    }
    else {
      wsansEl.innerHTML = wires[sizevs];
      wsloadansEl.innerHTML = volts - (volts * drop) + " V";
    }
  }
  else {
    document.getElementById("wsans").innerHTML = "Invalid";
    document.getElementById("wsloadans").innerHTML = "-";
  }
  return {gaugeResult: wires[sizevd], voltDropResult: volts - (volts * drop) + " V"};
}

function resetFunction() {
  document.getElementById("wsform").reset();
  document.getElementById("wsans").innerHTML = "-";
  document.getElementById("wsloadans").innerHTML = "-";
}

export {
  wiresizeFunction,
  resetFunction
};
