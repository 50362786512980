import {Accordion, Container} from "react-bootstrap";
import logo from "./logo2.png";

const TopContainer = () => (
  <Container className="App-why">
    <img src={logo} className="App-why-logo" alt="Solar Wire Size Calculator"/>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>The importance to select wires with a solar energy system</Accordion.Header>
        <Accordion.Body className="why-body">
          It's important to select wires that are properly sized for the currents and voltages in your solar energy system.
          <br />
          Wires that are too small will cause significant voltage drops, and therefore a significant solar energy loss, as well as possible overheating that may cause a fire.
          <br/>
          You can use our Solar Wire Size Calculator to select the proper wire for your needs.
          <br />
          Below you will find a detailed explanation on how to use the calculator, and how it selects the proper wire for the different sections of solar power systems.
          <br />
          We also offer amazon link of viable wires base on your result when possible.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </Container>
);

export default TopContainer;
