import {useState} from "react";
import {Formik} from "formik";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {AiFillCalculator, BsTrash} from "react-icons/all";

import ModalInfo from "./ModalInfo";
import AmazonLink from "./AmazonLink";
import {
  explanations,
  wireGauge
} from "./global";
import Notes from "./Notes";
import {wiresizeFunction} from "./wiresize";

const Calculator = () => {
  const [gauge, setGauge] = useState();
  const [voltDrop, setVoltDrop] = useState();
  console.log("gauge", gauge);
  const handleSubmitForm = ({
    voltsource,
    maxamps,
    phase,
    insulation,
    wscond,
    raceway,
    droppercent,
    conrun
  }) => {
    const {
      gaugeResult,
      voltDropResult,
    } = wiresizeFunction({voltsource, maxamps,phase,insulation,wscond,raceway,droppercent,conrun})

    setGauge(gaugeResult);
    setVoltDrop(voltDropResult);
  };

  return (
    <Container>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={{
          voltsource: 12,
          maxamps: 20,
          phase: "2",
          insulation: "2",
          wscond: "12.9",
          raceway: "0",
          droppercent: "0.05",
          conrun: 10,
        }}
      >
        {({
            handleSubmit,
            handleChange,
            handleReset,
            values,
            touched,
            isValid,
            errors,
          }) => (
          <Form
            className="form-horizontal"
            onSubmit={handleSubmit}
            role="form"
          >
            <Row className="g-2">
              <Form.Group as={Col} controlId="voltsource">
                <Form.Label>
                  Voltage (V):
                  <ModalInfo
                    title={explanations.voltage.title}
                    content={explanations.voltage.content}
                    video={explanations.voltage.video}
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  value={values.voltsource}
                  placeholder="Enter voltage at source."
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="maxamps">
                <Form.Label>
                  Amperes (A):
                  <ModalInfo
                    title={explanations.amperes.title}
                    content={explanations.amperes.content}
                    video={explanations.amperes.video}
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  value={values.maxamps}
                  placeholder="Enter maximum amps."
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className='g-2'>
              <Form.Group as={Col} controlId="wscond">
                <Form.Label>
                  Conductor:
                  <ModalInfo
                    title={explanations.conductor.title}
                    content={explanations.conductor.content}
                  />
                </Form.Label>
                <Form.Select
                  aria-label="wscond"
                  value={values.wscond}
                  onChange={handleChange}
                >
                  <option value="12.9">Copper</option>
                  <option value="21.2">Aluminum</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="raceway">
                <Form.Label>
                  Installation:
                  <ModalInfo
                    title={explanations.installation.title}
                    content={explanations.installation.content}
                  />
                </Form.Label>
                <Form.Select
                  aria-label="raceway"
                  value={values.raceway}
                  onChange={handleChange}
                >
                  <option value="0">Raceway</option>
                  <option value="1">Cable</option>
                  <option value="2">Burried in Earth</option>
                  <option value="3">Open Air</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="g-2">
              <Form.Group as={Col} controlId="droppercent">
                <Form.Label>
                  Voltage Drop:
                  <ModalInfo
                    title={explanations.voltagedrop.title}
                    content={explanations.voltagedrop.content}
                  />
                </Form.Label>
                <Form.Select
                  aria-label="droppercent"
                  value={values.droppercent}
                  onChange={handleChange}
                >
                  <option value="0.01">1%</option>
                  <option value="0.02">2%</option>
                  <option value="0.03">3%</option>
                  <option value="0.04">4%</option>
                  <option value="0.05">5%</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="conrun">
                <Form.Label>
                  Distance (ft):
                  <ModalInfo
                    title={explanations.distance.title}
                    content={explanations.distance.content}
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  value={values.conrun}
                  placeholder="Enter distance in feet."
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="g-2 buttons-calc">
              <Col>
                <Button
                  type="submit"
                  variant="warning"
                  color="white"
                >
                  <AiFillCalculator />
                  {" Calculate"}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  onClick={handleReset}
                >
                  <BsTrash color="white"/>
                  {" Reset"}
                </Button>
              </Col>
            </Row>

            <Notes gauge={gauge} voltDrop={voltDrop}>
              {
                gauge && wireGauge[gauge] && !gauge.includes("kcmil")
                  ? <AmazonLink link={wireGauge[gauge].amz} />
                  : null
              }
            </Notes>



          </Form>
        )}

      </Formik>
    </Container>
  )
};

export default Calculator;
