import "./Disclaimer.css";

import { Container } from "react-bootstrap";

const Disclaimer = () => (
  <div className="App-disclaimer">
      <Container>
        <div className="App-disclaimer-content">
          Copyright  Solar Wire Size Calculator© 2021
          <br />
          www.solarwiresizecalculator.com is a participant in the Amazon Services LLC Associates Program,
          an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com
          We make a commission for sales made through affiliate links posted on this website.
        </div>
      </Container>
  </div>
);

export default Disclaimer;
