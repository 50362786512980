import {Col, Form, Row} from "react-bootstrap";

const txtKcmil = "The gauge size base on these info would required a gauge larger than 0. " +
  "Your wire is either too long or too much current would pass in the system. " +
  "Maybe try with a higher voltage or Try to reduce your distance.";

const Notes = ({gauge = "", voltDrop = "", children}) => {
  return (
    <Row className="g-1 App-notes">
      <Col>
        <div className="results">
          <h4>Result:</h4>
          {
            gauge && gauge.includes("kcmil")
              ? (
                <div className="line-results">
                  {txtKcmil}
                </div>
              )
              : (
                <>
                  <div className="line-results">
                    <b>Wire Size: </b>
                    <span><span id="wsans"> {gauge} </span></span>
                  </div>
                  <div className="line-results">
                    <b>Voltage at Max Drop: </b>
                    <span><span id="wsloadans"> {voltDrop} </span></span>
                  </div>
                </>
              )
          }

        </div>
      </Col>
      <Form.Group>
        <div className="col-sm-12">
          <p>
            <b>Note</b>:
            <br/>
            When possible, we can recommend amazon products base on your results.
          </p>
          {children}
        </div>
      </Form.Group>
    </Row>
  )
};

export default Notes;
